import React from 'react';
import { Nav } from 'views';

const SecuredLayout = (props) => {
  const { children } = props;

  return (
    <div className="main-layout">
      <Nav />
      <main className="main-content">{children}</main>
    </div>
  );
};

export default SecuredLayout;
