import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { notifyErr } from '../../utils/toastMessages';
import Spinner from 'react-spinkit';

const AdOrderConfirmationModal = (props) => {
  const {
    modal,
    toggle,
    order,
    selectedOrder,
    shipping,
    setShipping,
    loading,
    createAutoDistOrder,
    setSelectedOrder,
  } = props;

  if (order && order.message) {
    notifyErr(order.message);
  }
  const addShippingCost = (e) => {
    let carrierCode = e.target.value;
    if (!carrierCode) {
      setShipping({
        cost: 0,
        carrierCode: '',
      });
      return;
    }
    order.shippingOptions.forEach((opt) => {
      if (opt.carrierCode === carrierCode) {
        setShipping({
          cost: opt.cost,
          carrierCode: opt.carrierCode,
        });
      }
    });
  };

  const sumOrderTotal = () => {
    let salesAmount = parseFloat(order.salesAmount);
    let summedOrderShipping = salesAmount + shipping.cost;
    return summedOrderShipping.toFixed(2);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader toggle={toggle}>
        Auto Distributors Calculated Order
      </ModalHeader>
      <ModalBody>
        <ListGroup>
          <ListGroupItem>
            <ListGroupItemHeading>Actions</ListGroupItemHeading>
            <Button color="primary" onClick={createAutoDistOrder}>
              {loading ? 'Placing Order' : 'Place Order'}
            </Button>{' '}
            <Button
              color="secondary"
              onClick={() => {
                setSelectedOrder({});
                setShipping({
                  cost: 0,
                  carrierCode: '',
                });
                toggle();
              }}
            >
              Cancel
            </Button>
            {loading ? (
              <Spinner name="three-bounce" className="cart-spinner" />
            ) : undefined}
          </ListGroupItem>
          {order && order.items ? (
            <>
              <ListGroupItem>
                <ListGroupItemText>
                  <br />
                  <span className="bold-label-text">
                    Sales Amount:{' '}
                  </span>
                  {`$${order.salesAmount}`}
                  <br />
                  {shipping.cost > 0 ? (
                    <>
                      <span className="bold-label-text">
                        Shipping Cost:{' '}
                      </span>
                      {`$${shipping.cost}`}
                      <br />
                      <span className="bold-label-text">
                        Order Total:{' '}
                      </span>
                      {`$${sumOrderTotal()}`}
                    </>
                  ) : undefined}
                </ListGroupItemText>
              </ListGroupItem>
              <ListGroupItem>
                <ListGroupItemHeading>
                  Calculated Order
                </ListGroupItemHeading>
                <ListGroupItemText>
                  <span className="bold-label-text">PO Number:</span>{' '}
                  {selectedOrder.orderId}
                  <br />
                  {order.items.map((item, idx) => (
                    <span key={item.number}>
                      <br />
                      <span className="bold-label-text">
                        Item Number:
                      </span>{' '}
                      {item.number}
                      <br />
                      <span className="bold-label-text">
                        Item Name:
                      </span>{' '}
                      {item.name}
                      <br />
                      <span className="bold-label-text">
                        Item Quantity:
                      </span>{' '}
                      {item.quantity}
                      <br />
                    </span>
                  ))}
                  <br />
                  <span className="bold-label-text">
                    Order Weight:
                  </span>{' '}
                  {order.totalOrderWeight}
                </ListGroupItemText>
                <FormGroup>
                  <Label
                    for="exampleSelect"
                    className="bold-label-text"
                  >
                    Shipping Method:
                  </Label>
                  <Input
                    id="shippingSelect"
                    name="select"
                    type="select"
                    onChange={addShippingCost}
                  >
                    <option value="">
                      Please Select a Shipping Method
                    </option>
                    {order.shippingOptions.map((opt) => {
                      return (
                        <option
                          value={opt.carrierCode}
                          key={opt.carrierCode}
                        >{`${opt.name} - $${opt.cost}`}</option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </ListGroupItem>
            </>
          ) : undefined}
        </ListGroup>
      </ModalBody>
    </Modal>
  );
};

// AdOrderConfirmationModal.propTypes = {
//   toggle: PropTypes.func.isRequired,
//   modal: PropTypes.bool.isRequired,
//   setSelectedOrder: PropTypes.func.isRequired,
//   confirmCartOrder: PropTypes.func.isRequired,
//   setLoading: PropTypes.func.isRequired,
//   loading: PropTypes.bool.isRequired,
// };

export default AdOrderConfirmationModal;
