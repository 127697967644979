import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { WpsTable } from './Table';

const WpsProducts = () => {
  const [data, setData] = useState([]);
  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Sku',
      accessor: 'sku',
    },
    {
      Header: 'Supplier ID',
      accessor: 'supplierID',
    },
    {
      Header: 'Quantity',
      accessor: 'quantityOnHand',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
  ]);

  const fetchAllProducts = async () => {
    try {
      let response = await axios.get(
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/products/ed/all?apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/ed/all?apiKey=${process.env.REACT_APP_API_KEY}`,
      );
      setData(response.data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <Container className="content-container">
      <Row className="justify-content-center page-header">
        <Col xs="12" className="text-center page-title">
          <img
            className="cascade-logo mb-3"
            src="/images/cascade-logo.jpg"
            alt=""
          />
          <h1>WPS Products</h1>
        </Col>
        <Col xs="12" className="text-center wps-product-subtitle">
          <p>
            Sync products list to update list with newest products
            from your EcomDash account.
          </p>
        </Col>
      </Row>
      <WpsTable columns={columns} data={data} />
    </Container>
  );
};

export default WpsProducts;
