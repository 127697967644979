import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card } from 'reactstrap';
import moment from 'moment';
import axios from 'axios';

const BulkSync = () => {
  const [recentSyncDetails, setRecentSyncDetails] = useState([]);

  useEffect(() => {
    const getJobDetails = async () => {
      try {
        const recentResponse = await axios.get(
          process.env.NODE_ENV === 'development'
            ? `${process.env.REACT_APP_API_ENDPOINT}/details/getDetail/recentSync`
            : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/details/getDetail/recentSync`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                'x-token',
              )}`,
            },
          },
        );
        let filteredRecent = recentResponse.data.jobDetails.filter(
          (dtl) => {
            if (dtl.jobDate === moment().format('MM-DD-YYYY')) {
              return dtl;
            }
          },
        );

        setRecentSyncDetails(filteredRecent);
      } catch (err) {
        console.log(err.message);
      }
    };
    getJobDetails();
  }, []);

  return (
    <>
      <Container className="content-container">
        <Row className="justify-content-center page-header">
          <Col xs="12" className="text-center page-title">
            <img
              className="cascade-logo mb-3"
              src="/images/cascade-logo.jpg"
              alt=""
            />
            <h1>Sync Log</h1>
          </Col>
        </Row>
        <Row>
          <Card xs="12" className="bulk-sync-cards text-center">
            <Col xs="12">
              <h3>Daily Products Sync Job</h3>
              {recentSyncDetails.length > 0 ? (
                recentSyncDetails.map((dtl) => {
                  return (
                    <div className="details-box mb-3" key={dtl.id}>
                      <p>Job Title: {dtl.jobTitle}</p>
                      <p>Job Started: {dtl.jobInitiated}</p>
                      <p>Job Completed: {dtl.jobCompleted}</p>
                    </div>
                  );
                })
              ) : (
                <p className="text-danger font-weight-bold">
                  No jobs run so far today
                </p>
              )}
            </Col>
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default BulkSync;
