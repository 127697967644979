import React from 'react';

const MinimalLayout = (props) => {
  const { children } = props;

  return (
    <div className="minimal-layout">
      <main className="minimal-content">{children}</main>
    </div>
  );
};

export default MinimalLayout;
