import axios from 'axios';

// ORDER STATUS OPTIONS
// awaiting_payment
// awaiting_shipment
// pending_fulfillment
// shipped
// on_hold
// cancelled
export const getShipStationOrders = async (status) => {
  try {
    const response = await axios.get(
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_API_ENDPOINT}/products/ss-orders?status=${status}&apiKey=${process.env.REACT_APP_API_KEY}`
        : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/ss-orders?status=${status}&apiKey=${process.env.REACT_APP_API_KEY}`,
    );
    return await response;
  } catch (err) {
    return err.response;
  }
};

// Grab a single ship station order
export const getSingleShipStationOrder = async (orderId) => {
  try {
    const response = await axios.get(
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_API_ENDPOINT}/products/single-ss-orders/${orderId}?apiKey=${process.env.REACT_APP_API_KEY}`
        : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/single-ss-orders/${orderId}?apiKey=${process.env.REACT_APP_API_KEY}`,
    );
    return await response;
  } catch (err) {
    console.log(err.message);
  }
};

// Get Product SKU from WPS with Sku or Supplier Product ID
export const getProductSku = async (sku) => {
  try {
    const response = await axios.get(
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_API_ENDPOINT}/products/wps/${sku}?apiKey=${process.env.REACT_APP_API_KEY}`
        : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/wps/${sku}?apiKey=${process.env.REACT_APP_API_KEY}`,
    );
    return response;
  } catch (err) {
    console.log(err.message);
  }
};

export const markAsShipped = async (orderId, carrierCode) => {
  try {
    let body = {
      orderId,
      carrierCode,
      notifyCustomer: false,
      notifySalesChannel: false,
    };
    const response = await axios({
      url:
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/products/mark-ss-shipped/?apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/mark-ss-shipped/?apiKey=${process.env.REACT_APP_API_KEY}`,
      method: 'post',
      data: body,
    });
    return response;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const deleteCart = async (poNumber) => {
  try {
    await axios.delete(
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_API_ENDPOINT}/products/wps/deleteCart/${poNumber}?apiKey=${process.env.REACT_APP_API_KEY}`
        : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/wps/deleteCart/${poNumber}?apiKey=${process.env.REACT_APP_API_KEY}`,
    );
    return true;
  } catch (err) {
    console.log(err.message);
  }
};

export const addItemsToCart = async (items, orderNumber) => {
  let cartItems = [];
  items.forEach((item) => {
    cartItems.push({
      item_sku: item.sku,
      quantity: item.quantity,
    });
  });
  try {
    let cartItemsAdded = await axios({
      url:
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/products/wps/addCartItems/${orderNumber}?apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/wps/addCartItems/${orderNumber}?apiKey=${process.env.REACT_APP_API_KEY}`,
      method: 'post',
      data: cartItems,
    });
    return cartItemsAdded;
  } catch (err) {
    console.log(err);
    return err.message;
  }
};

// This function creates the cart and adds the item to it
export const createWpsCart = async (order) => {
  let data = {
    po_number: order.orderNumber,
    ship_name: order.shipTo.name,
    ship_address1: order.shipTo.street1,
    ship_city: order.shipTo.city,
    ship_state: order.shipTo.state,
    ship_zip: order.shipTo.postalCode,
    ship_phone: order.shipTo.phone,
    email: order.customerEmail,
    ship_via: 'BEST',
    allow_backorder: false,
  };

  if (order.shipTo.street2) {
    data.ship_address2 = order.shipTo.street2;
  }
  if (order.shipTo.street3) {
    data.ship_address3 = order.shipTo.street3;
  }

  //** CREATE THE CART IN WPS HERE **//
  try {
    let cart = await axios({
      url:
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/products/wps/createCart?apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/wps/createCart?apiKey=${process.env.REACT_APP_API_KEY}`,
      method: 'post',
      data,
    });
    return cart.data;
  } catch (err) {
    console.log(err);
    return err.message;
  }
};

export const getCartInfo = async (poNumber) => {
  try {
    let cartInfo = await axios.get(
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_API_ENDPOINT}/products/wps/cartInfo/${poNumber}?apiKey=${process.env.REACT_APP_API_KEY}`
        : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/wps/cartInfo/${poNumber}?apiKey=${process.env.REACT_APP_API_KEY}`,
    );
    return cartInfo;
  } catch (err) {
    return err.response;
  }
};

export const submitCart = async (poNumber) => {
  try {
    let submitCart = await axios.post(
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_API_ENDPOINT}/products/wps/submitCart/${poNumber}?apiKey=${process.env.REACT_APP_API_KEY}`
        : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/wps/submitCart/${poNumber}?apiKey=${process.env.REACT_APP_API_KEY}`,
    );
    return submitCart;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const createDropshipOrder = async (poNumber, orderId) => {
  try {
    const response = await axios({
      url:
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/dropship/create/?poNumber=${poNumber}&orderId=${orderId}&apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/dropship/create/?poNumber=${poNumber}&orderId=${orderId}&apiKey=${process.env.REACT_APP_API_KEY}`,
      method: 'post',
    });
    if (response.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(`Error: ${err.response.data.message}`);
    return false;
  }
};

// Update Drop Ship order
export const updateDropshipOrder = async (poNumber, orderId) => {
  try {
    const response = await axios({
      url:
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/dropship/update/?poNumber=${poNumber}&orderId=${orderId}&apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/dropship/update/?poNumber=${poNumber}&orderId=${orderId}&apiKey=${process.env.REACT_APP_API_KEY}`,
      method: 'post',
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log(`Error: ${err.response.data.message}`);
    return false;
  }
};

// Update Bulk Drop Ship order
export const updateBulkDropshipOrder = async () => {
  try {
    const response = await axios({
      url:
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/dropship/update/bulk?apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/dropship/update/bulk?apiKey=${process.env.REACT_APP_API_KEY}`,
      method: 'post',
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log(`Error: ${err.response.data.message}`);
    return false;
  }
};

// Get all drop ship orders
export const getDropshipOrders = async () => {
  try {
    const response = await axios({
      url:
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/dropship/orders?apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/dropship/orders?apiKey=${process.env.REACT_APP_API_KEY}`,
      method: 'get',
    });
    return await response;
  } catch (err) {
    console.log(`Error: ${err.response.data.message}`);
    return err.response;
  }
};

// Calculate Auto Dist Order
export const calculateAdOrder = async (params) => {
  try {
    const response = await axios({
      url:
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/dropship/ad/calculate?apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/dropship/ad/calculate?apiKey=${process.env.REACT_APP_API_KEY}`,
      method: 'post',
      data: params,
    });
    return await response;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    return err.response;
  }
};

// Create Auto Dist Order
export const createAdOrder = async (params) => {
  try {
    const response = await axios({
      url:
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/dropship/ad/create?apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/dropship/ad/create?apiKey=${process.env.REACT_APP_API_KEY}`,
      method: 'post',
      data: params,
    });
    return await response;
  } catch (err) {
    return err.message;
  }
};
