import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { OrderedTable } from './OrderedTable';
import {
  getDropshipOrders,
  updateDropshipOrder,
  updateBulkDropshipOrder,
} from '../../utils/functions';
import { notifyErr, notifySuccess } from 'utils/toastMessages';

const FulfillOrders = () => {
  const [data, setData] = useState([]);

  const removeItemFromList = async (poNumber) => {
    let newData = data.filter(
      (item) => parseInt(item.poNumber) !== parseInt(poNumber),
    );
    setData(newData);
  };

  const updateTrackingInformation = async (poNumber, orderId) => {
    const updatedOrder = await updateDropshipOrder(poNumber, orderId);
    if (updatedOrder.poNumber) {
      notifySuccess(updatedOrder.message);
      removeItemFromList(poNumber);
    } else {
      notifyErr(updatedOrder.message);
    }
  };

  const handleBulkTrackingUpdateSync = async () => {
    await updateBulkDropshipOrder();
    fetchAllProducts();
  };

  const columns = useMemo(() => [
    {
      Header: 'PO Number',
      accessor: 'poNumber',
    },
    {
      Header: 'Name',
      accessor: 'shipName',
    },
    {
      Header: 'Order Status',
      accessor: 'orderStatus',
    },
    {
      Header: 'Order Date',
      accessor: 'orderDate',
    },
    {
      Header: 'Shipping Method',
      accessor: 'shippingMethod',
    },
    {
      Header: 'Order ID',
      accessor: 'shipStationOrderId',
    },
    {
      Header: 'Action',
      Cell: ({ cell }) => (
        <Button
          value={'Something'}
          color="primary"
          onClick={() => {
            updateTrackingInformation(
              cell.row.values.poNumber,
              cell.row.values.shipStationOrderId,
            );
          }}
        >
          Sync
        </Button>
      ),
    },
  ]);

  const fetchAllProducts = async () => {
    try {
      let response = await getDropshipOrders();
      let formattedOrders = response.data.map((obj) => {
        return {
          ...obj,
          shipName: obj.shipName.replace('+', ' '),
        };
      });
      setData(formattedOrders);
      return;
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <Container className="content-container">
      <Row className="justify-content-center page-header">
        <Col xs="12" className="text-center page-title">
          <img
            className="cascade-logo mb-3"
            src="/images/cascade-logo.jpg"
            alt=""
          />
          <h1>Drop Ship Orders Placed</h1>
        </Col>
        <Col xs="12" className="text-center wps-product-subtitle">
          <p>
            These are all of the drop ship orders that have been
            placed with this portal. Once the orders have been shipped
            they will drop off of this list. Order statuses will
            automatically be synced every hour after 5 PM until 11 PM
            or you can manually sync order status by clicking on the
            button right below this text.
          </p>
        </Col>
        <Col xs="12" className="text-center">
          <Button
            color="info"
            outline
            onClick={handleBulkTrackingUpdateSync}
          >
            Manual Sync All Orders
          </Button>
        </Col>
      </Row>
      <OrderedTable columns={columns} data={data} />
    </Container>
  );
};

export default FulfillOrders;
