import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
  deleteCart,
  markAsShipped,
  submitCart,
  createDropshipOrder,
} from '../../utils/functions';
import { notifySuccess, notifyErr } from '../../utils/toastMessages';

const ConfirmModal = (props) => {
  const {
    modal,
    toggle,
    cart,
    setSelectedOrder,
    selectedOrder,
    data,
    setData,
    setCart,
  } = props;

  const removeItemFromList = async (poNumber) => {
    let newData = data.filter(
      (item) => item.orderNumber !== poNumber,
    );
    setData(newData);
  };

  const handleSubmitCart = async (poNumber) => {
    let submittedOrder = await submitCart(poNumber);
    if (submittedOrder.data && submittedOrder.data.status === 'OK') {
      await markAsShipped(
        selectedOrder.orderId,
        selectedOrder.carrierCode,
      );
      if (poNumber && selectedOrder.orderId) {
        await createDropshipOrder(poNumber, selectedOrder.orderId);
      }
      setSelectedOrder({});
      notifySuccess('Cart Successfully Submitted!');
      removeItemFromList(poNumber);
      toggle();
    } else {
      notifyErr(
        'Something went wrong when trying to submit the cart',
      );
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader toggle={toggle}>Submit Cart Review</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <p>
            Your cart has been created and the item(s) have been
            successfully added to the cart. Please review the details
            here before Submitting the Cart
          </p>
        </div>
        <ListGroup>
          <ListGroupItem>
            <ListGroupItemHeading>Actions</ListGroupItemHeading>
            <Button
              color="primary"
              onClick={() => {
                handleSubmitCart(cart.po_number);
              }}
            >
              Place Order
            </Button>
            <Button
              color="secondary"
              className="ml-2"
              onClick={() => {
                deleteCart(cart.po_number);
                setSelectedOrder({});
                setCart({});
                toggle();
              }}
            >
              Cancel Order
            </Button>
          </ListGroupItem>
          <ListGroupItem>
            <ListGroupItemHeading>Ship To</ListGroupItemHeading>
            {cart ? (
              <ListGroupItemText>
                <span className="bold-label-text">Name:</span>{' '}
                {cart.ship_name}
                <br />
                <span className="bold-label-text">Address:</span>{' '}
                {cart.ship_address1}
                <br />
                {cart.ship_address2 ? (
                  <span>
                    <span className="bold-label-text">
                      Address 2:
                    </span>{' '}
                    {cart.ship_address2}
                    <br />
                  </span>
                ) : undefined}
                {cart.ship_address3 ? (
                  <span>
                    <span className="bold-label-text">
                      Address 3:
                    </span>{' '}
                    {cart.ship_address3}
                    <br />
                  </span>
                ) : undefined}
                <span className="bold-label-text">State:</span>{' '}
                {cart.ship_state}
                <br />
                <span className="bold-label-text">City:</span>{' '}
                {cart.ship_city}
                <br />
                <span className="bold-label-text">Zip:</span>{' '}
                {cart.ship_zip}
                <br />
              </ListGroupItemText>
            ) : undefined}
          </ListGroupItem>
          <ListGroupItem>
            <ListGroupItemHeading>
              Ordered Item(s)
            </ListGroupItemHeading>
            {cart.items ? (
              <ListGroupItemText>
                {cart.items.map((item, idx) => (
                  <span key={idx}>
                    <span className="bold-label-text">Quantity:</span>{' '}
                    {item.quantity}
                    <br />
                    <span className="bold-label-text">Sku:</span>{' '}
                    {item.item}
                    <br />
                  </span>
                ))}
              </ListGroupItemText>
            ) : undefined}
          </ListGroupItem>
        </ListGroup>
      </ModalBody>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  setSelectedOrder: PropTypes.func.isRequired,
  selectedOrder: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  data: PropTypes.array,
  setData: PropTypes.func.isRequired,
  setCart: PropTypes.func.isRequired,
};

export default ConfirmModal;
