import React from 'react';
import { Formik, Form } from 'formik';
import { Button, FormGroup, Label, Input } from 'reactstrap';

const UpdateQuantityForm = (props) => {
  return (
    <Formik
      initialValues={{
        adSku: '',
        wpsSku: '',
      }}
      onSubmit={(values) => {
        props.handleRequest(values, props.supplier);
      }}
    >
      {({ values, errors, touched, handleChange }) => (
        <Form>
          <FormGroup>
            {props.supplier === 'WPS' ? (
              <>
                <Label for="wpsSku">
                  {props.supplier} Product Search
                </Label>
                <Input
                  type="text"
                  name="wpsSku"
                  id="wpsSku"
                  placeholder="Enter SKU or Supplier Product ID"
                  value={values.wpsSku}
                  onChange={handleChange}
                  error={touched.wpsSku && errors.wpsSku}
                />
              </>
            ) : (
              <>
                <Label for="adSku">
                  {props.supplier} Product Search
                </Label>
                <Input
                  type="text"
                  name="adSku"
                  id="adSku"
                  placeholder="Enter SKU or Manufacturer Number"
                  value={values.adSku}
                  onChange={handleChange}
                  error={touched.adSku && errors.adSku}
                />
              </>
            )}
          </FormGroup>
          {props.supplier === 'WPS' ? (
            <Button
              color="success"
              type="submit"
              disabled={!values.wpsSku}
            >
              Search
            </Button>
          ) : (
            <Button
              color="success"
              type="submit"
              disabled={!values.adSku}
            >
              Search
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UpdateQuantityForm;
