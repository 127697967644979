import axios from 'axios';

export const verifyToken = async () => {
  if (localStorage.getItem('x-token')) {
    try {
      const verifyToken = await axios.get(
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/settings/verifyToken`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/settings/verifyToken`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              'x-token',
            )}`,
          },
        },
      );
      if (verifyToken.data.message === 'OK') {
        return true;
      }
    } catch (err) {
      console.error(err.message);
      return err.message;
    }
  } else {
    return false;
  }
};
