import { toast } from 'react-toastify';

export const notifyErr = (message) =>
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
  });

export const notifySuccess = (message) =>
  toast.info(message, {
    position: toast.POSITION.TOP_CENTER,
  });
