import React from 'react';
import {
  RouteWithLayout,
  ProtectedRouteWithLayout,
} from 'RouteWithLayout';
import { Switch } from 'react-router-dom';
import SecuredLayout from 'layouts/SecuredLayout';
import MinimalLayout from 'layouts/MinimalLayout';
import {
  SingleSync,
  BulkSync,
  WpsProducts,
  LoginPage,
  FulfillOrders,
  DropShip,
} from 'views';

const Routes = () => {
  return (
    <Switch>
      <ProtectedRouteWithLayout
        component={BulkSync}
        exact
        layout={SecuredLayout}
        path="/"
      />
      <ProtectedRouteWithLayout
        component={SingleSync}
        exact
        layout={SecuredLayout}
        path="/single-sync"
      />
      <ProtectedRouteWithLayout
        component={WpsProducts}
        exact
        layout={SecuredLayout}
        path="/wps-products"
      />
      <ProtectedRouteWithLayout
        component={FulfillOrders}
        exact
        layout={SecuredLayout}
        path="/fulfill"
      />
      <ProtectedRouteWithLayout
        component={DropShip}
        exact
        layout={SecuredLayout}
        path="/dropship"
      />
      <RouteWithLayout
        component={LoginPage}
        exact
        layout={MinimalLayout}
        path="/login"
      />
    </Switch>
  );
};

export default Routes;
