import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import { useAppStateDispatch } from 'AppState';
import axios from 'axios';

const LoginPage = (props) => {
  const dispatch = useAppStateDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    let username = e.target.username.value;
    let password = e.target.password.value;
    if (!username) {
      return alert('Please enter a username to login');
    }
    if (!password) {
      return alert('Please provide a password to login');
    }
    try {
      const login = await axios.post(
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/user/login?apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/user/login?apiKey=${process.env.REACT_APP_API_KEY}`,
        {
          username,
          password,
        },
      );
      if (login.data.message) {
        alert(login.data.message);
        return;
      }
      localStorage.setItem('x-token', login.data.token);
      dispatch({ type: 'login' });
      props.history.push('/fulfill');
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-5 login-banner text-center">
          <h1 className="login-title">SYNC JOB DASHBOARD</h1>
          <img
            className="cascade-logo mb-3"
            src="/images/cascade-logo.jpg"
            alt=""
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={12}>
          <Card
            className="my-2 mx-auto"
            style={{
              width: '30rem',
            }}
          >
            <CardBody className="mx-auto">
              <Row>
                <Form onSubmit={handleLogin}>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="username">Username</Label>
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Username"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <Button className="login-btn btn-block">
                      Login
                    </Button>
                  </Col>
                </Form>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
