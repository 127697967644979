import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Button,
} from 'reactstrap';
import { useAppStateDispatch } from 'AppState';

const Navigation = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppStateDispatch();
  const toggle = () => setIsOpen(!isOpen);

  const handleLogout = () => {
    dispatch({ type: 'logout' });
    localStorage.removeItem('x-token');
  };

  return (
    <div>
      <Navbar expand="md">
        <NavbarBrand href="/">WPS Sync</NavbarBrand>
        <NavbarToggler onClick={toggle} className="text-white" />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/single-sync">
                Single Product Sync
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/wps-products">WPS Products</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/fulfill">Fulfill Dropship</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/dropship">DropShip Orders</NavLink>
            </NavItem>
          </Nav>
          <NavbarText>
            <Button onClick={handleLogout}>Logout</Button>
          </NavbarText>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
