import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppStateContext } from 'AppState';

export const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export const ProtectedRouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;
  const state = useAppStateContext();
  return (
    <Route
      {...rest}
      render={(matchProps) =>
        state.isAuth ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
