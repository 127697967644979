import * as React from 'react';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const loginReducer = (state, action) => {
  switch (action.type) {
    case 'login': {
      return { isAuth: true };
    }
    case 'logout': {
      return { isAuth: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(loginReducer, {
    isAuth: localStorage.getItem('x-token') ? true : false,
  });

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

const useAppStateContext = () => {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error(
      'useAppStateContext must be used within a AppProvider',
    );
  }
  return context;
};

const useAppStateDispatch = () => {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAppStateDispatch must be used within a AppProvider',
    );
  }
  return context;
};

export { AppProvider, useAppStateContext, useAppStateDispatch };
