import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { createWpsCart, addItemsToCart } from '../../utils/functions';
import { notifySuccess, notifyErr } from '../../utils/toastMessages';
import Spinner from 'react-spinkit';

const OrderInfoModal = (props) => {
  const {
    modal,
    toggle,
    order,
    setSelectedOrder,
    confirmCartOrder,
    setLoading,
    loading,
  } = props;

  const clearAndClose = () => {
    toggle();
    setSelectedOrder({});
    setLoading(false);
  };

  const addressStatusText = (addressVerified) => {
    return addressVerified.includes('warning')
      ? 'warn-text'
      : 'success-text';
  };

  const addressStatusBg = (addressVerified) => {
    return addressVerified.includes('warning') ? 'warn-bg' : '';
  };

  const handleCartCreation = async () => {
    // Check for valid address
    if (order.shipTo.addressVerified.includes('warning')) {
      notifyErr(
        'This address has not been verified in ShipStation! Please login to ShipStation and correct this address so that it reflects that it has been validated successfully.',
      );
      clearAndClose();
      return;
    }
    if (order.shipTo.phone.length > 15) {
      notifyErr(
        `Phone number cannot be longer than 15 characters ${order.shipTo.phone}`,
      );
      clearAndClose();
      return;
    }
    if (order.shipTo.name.length > 30) {
      notifyErr(
        `Ship to name cannot be longer than 30 characters ${order.shipTo.name}`,
      );
      clearAndClose();
      return;
    }
    if (order.shipTo.state.length > 2) {
      notifyErr(
        `Ship to state cannot be longer than 2 characters ${order.shipTo.state}`,
      );
      clearAndClose();
      return;
    }
    setLoading(true);

    // ! Create the WPS cart
    let newCart = await createWpsCart(order);
    if (newCart.errors) {
      clearAndClose();
      setLoading(false);
      notifyErr(`${newCart.message}`);
      console.log(newCart);
      return;
    }
    // ! Add items to the cart
    if (newCart.status === 'OK') {
      let addItems = await addItemsToCart(
        order.items,
        order.orderNumber,
      );

      if (addItems.data.status === 'OK') {
        notifySuccess('Cart was successfully created!');
        confirmCartOrder(order.orderNumber);
        setLoading(false);
      } else {
        notifyErr(`${addItems.data.message}`);
        clearAndClose();
        setLoading(false);
      }
    } else {
      notifyErr(
        `Cart was not created! There was an error - ${newCart}`,
      );
      clearAndClose();
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader toggle={toggle}>Review Order</ModalHeader>
      <ModalBody>
        <ListGroup>
          <ListGroupItem>
            <ListGroupItemHeading>Actions</ListGroupItemHeading>
            <Button
              color="primary"
              onClick={handleCartCreation}
              disabled={loading}
            >
              {loading ? 'Creating Cart' : 'Create Cart'}
            </Button>{' '}
            <Button
              color="secondary"
              onClick={() => {
                setLoading(false);
                clearAndClose();
              }}
            >
              Cancel Cart
            </Button>
            {loading ? (
              <Spinner name="three-bounce" className="cart-spinner" />
            ) : undefined}
          </ListGroupItem>
          {order.shipTo ? (
            <>
              <ListGroupItem>
                <ListGroupItemHeading>
                  Order Information
                </ListGroupItemHeading>
                <ListGroupItemText>
                  <span className="bold-label-text">Order ID:</span>{' '}
                  {order.orderId}
                  <br />
                  <span className="bold-label-text">
                    Order #:
                  </span>{' '}
                  {order.orderNumber}
                </ListGroupItemText>
              </ListGroupItem>
              <ListGroupItem
                className={addressStatusBg(
                  order.shipTo.addressVerified,
                )}
              >
                <ListGroupItemHeading>
                  Shipping Address
                </ListGroupItemHeading>
                <ListGroupItemText>
                  <span
                    className={addressStatusText(
                      order.shipTo.addressVerified,
                    )}
                  >
                    {order.shipTo.addressVerified}
                  </span>
                  <br />
                  <span className="bold-label-text">
                    Ship To:
                  </span>{' '}
                  {order.shipTo.name}
                  <br />
                  <span className="bold-label-text">
                    Address:
                  </span>{' '}
                  {order.shipTo.street1}
                  <br />
                  {order.shipTo.street2 ? (
                    <span>
                      <span className="bold-label-text">
                        Address:
                      </span>{' '}
                      {order.shipTo.street2}
                      <br />
                    </span>
                  ) : undefined}
                  <span className="bold-label-text">City:</span>{' '}
                  {order.shipTo.city}
                  <br />
                  <span className="bold-label-text">State:</span>{' '}
                  {order.shipTo.state}
                  <br />
                  <span className="bold-label-text">Zip:</span>{' '}
                  {order.shipTo.postalCode}
                  <br />
                  <span className="bold-label-text">
                    Requested Shipping:
                  </span>{' '}
                  {order.requestedShippingService}
                </ListGroupItemText>
              </ListGroupItem>
              <ListGroupItem>
                <ListGroupItemHeading className="mt-3">
                  Ordered Items
                </ListGroupItemHeading>
                <ListGroupItemText>
                  {order.items.map((item, idx) => {
                    return (
                      <span key={idx}>
                        <span className="bold-label-text">
                          Item Name:
                        </span>{' '}
                        {item.name}
                        <br />
                        <span className="bold-label-text">
                          Item Sku:
                        </span>{' '}
                        {item.sku}
                        <br />
                        <span className="bold-label-text">
                          Item Quantity:
                        </span>{' '}
                        {item.quantity}
                        <br />
                        <br />
                      </span>
                    );
                  })}
                </ListGroupItemText>
              </ListGroupItem>
            </>
          ) : undefined}
        </ListGroup>
      </ModalBody>
    </Modal>
  );
};

OrderInfoModal.propTypes = {
  order: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  setSelectedOrder: PropTypes.func.isRequired,
  confirmCartOrder: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default OrderInfoModal;
