import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { OrdersTable } from './OrdersTable';
import {
  getShipStationOrders,
  getSingleShipStationOrder,
  getCartInfo,
  getProductSku,
  calculateAdOrder,
  createAdOrder,
  markAsShipped,
} from '../../utils/functions';
import OrderInfoModal from './OrderInfoModal';
import ConfirmModal from './ConfirmModal';
import AdOrderConfirmationModal from './AdOrderConfirmationModal';
import { notifyErr, notifySuccess } from 'utils/toastMessages';

const FulfillOrders = () => {
  const [data, setData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [calculatedOrder, setCalculatedOrder] = useState();
  const [modal, setModal] = useState(false);
  const [adConfirmModal, setAdConfirmModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [cart, setCart] = useState({});
  const [loading, setLoading] = useState(false);
  const [adSearching, setAdSearching] = useState({});
  const [shipping, setShipping] = useState({
    cost: 0,
    carrierCode: '',
  });

  const removeItemFromList = async (poNumber) => {
    let newData = data.filter((item) => item.orderId !== poNumber);
    setData(newData);
  };

  const toggle = () => setModal(!modal);
  const toggleConfirm = () => setConfirmModal(!confirmModal);
  const toggleAdConfirm = () => setAdConfirmModal(!adConfirmModal);

  const createAutoDistOrder = async () => {
    if (!shipping.carrierCode) {
      notifyErr('Please select a shipping method!');
      return;
    }
    let orderBody = {
      PONumber: selectedOrder.orderId,
      // PONumber: 242412367,
      placeOrderOnBackorder: false,
      header: {
        shippingAddress: {
          name: selectedOrder.shipTo.name,
          addressLine1: selectedOrder.shipTo.street1,
          addressLine2: selectedOrder.shipTo.street2,
          addressLine3: selectedOrder.shipTo.street3,
          city: selectedOrder.shipTo.city,
          state: selectedOrder.shipTo.state,
          zipCode: selectedOrder.shipTo.postalCode,
          countryCode: selectedOrder.shipTo.country,
          phone: selectedOrder.shipTo.phone,
          classification: 'RESIDENTIAL',
        },
        shippingMethod: shipping.carrierCode,
      },
      items: [],
    };

    calculatedOrder.items.forEach((item) => {
      orderBody.items.push({
        quantity: item.quantity,
        number: item.number,
      });
    });

    try {
      let response = await createAdOrder(orderBody);
      if (!response.data) {
        notifyErr(
          'Could not create this order, this might be an order number duplicate in Auto Distributors already.',
        );
        return;
      }

      if (
        response.data.header.message ===
        'sales order created successfully'
      ) {
        const shipStationOrderId = response.data.header.ponumber;
        const carrierCode = shipping.carrierCode;
        await markAsShipped(shipStationOrderId, carrierCode);
        removeItemFromList(selectedOrder.orderId);
        notifySuccess(response.data.header.message);
        toggleAdConfirm();
        setShipping({
          cost: 0,
          carrierCode: '',
        });

        setCalculatedOrder({});
        return;
      }
      return;
    } catch (err) {
      notifyErr(err.message);
      return;
    }
  };

  const handleOrder = async (data) => {
    let body = {
      PONumber: data.orderId,
      placeOrderOnBackorder: false,
      header: {
        shippingAddress: {
          name: data.shipTo.name,
          contact: '',
          addressLine1: data.shipTo.street1,
          addressLine2: data.shipTo.street2,
          addressLine3: data.shipTo.street3,
          city: data.shipTo.city,
          state: data.shipTo.state,
          zipCode: data.shipTo.postalCode,
          countryCode: data.shipTo.country,
          phone: data.shipTo.phone,
          classification: 'RESIDENTIAL',
        },
      },
      items: [],
    };

    data.items.forEach((item) => {
      body.items.push({
        quantity: item.quantity,
        number: item.sku,
      });
    });

    try {
      let response = await calculateAdOrder(body);
      if (!response.data) {
        notifyErr('Could not receive a response back from the API.');
        setAdSearching({
          [data.orderId]: false,
        });
        return;
      }
      response.data.poNumber = data.orderId;
      if (response.data.error) {
        notifyErr(response.data.message);
        setAdSearching({
          [data.orderId]: false,
        });
        return;
      }
      setSelectedOrder(data);
      let nameArr = [];
      data.items.forEach((p) => {
        nameArr.push(p.name);
      });
      setCalculatedOrder(response.data);
      setAdSearching({
        [data.orderId]: false,
      });
      toggleAdConfirm();
      return;
    } catch (err) {
      setAdSearching({
        [data.orderId]: false,
      });
      notifyErr(err.message);
      return;
    }
  };

  const handleWpsInitOrder = async (orderId, items) => {
    getSingleShipStationOrder(orderId)
      .then((data) => {
        toggle();
        return setSelectedOrder(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleAdInitOrder = async (orderId, items) => {
    setAdSearching({
      [orderId]: true,
    });
    getSingleShipStationOrder(orderId)
      .then((data) => {
        handleOrder(data.data);
        return;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const columns = useMemo(() => [
    {
      Header: 'Order ID',
      accessor: 'orderId',
    },
    {
      Header: 'Name',
      accessor: 'shipTo.name',
    },
    {
      Header: 'Product',
      Cell: ({ cell }) => {
        const items = cell.row.values.items;
        let itemsNameList = [];
        if (items.length > 1) {
          items.forEach((item) => {
            itemsNameList.push(item.name);
          });
          return (
            <p style={{ whiteSpace: 'pre-line' }}>
              {itemsNameList.join('\r\n\n')}
            </p>
          );
        } else {
          return items[0].name;
        }
      },
    },
    {
      Header: 'Quantity',
      accessor: 'items',
      Cell: ({ cell }) => {
        const items = cell.row.values.items;
        let itemsQtyList = [];
        if (items.length > 1) {
          items.forEach((item) => {
            itemsQtyList.push(item.quantity);
          });
          return (
            <p className="mt-3" style={{ whiteSpace: 'pre-line' }}>
              {itemsQtyList.join('\r\n\n\n\n')}
            </p>
          );
        } else {
          return items[0].quantity;
        }
      },
    },
    {
      Header: 'Sku',
      Cell: ({ cell }) => {
        const items = cell.row.values.items;
        let itemsSkuList = [];
        if (items.length > 1) {
          items.forEach((item) => {
            itemsSkuList.push(item.sku);
          });
          return (
            <p className="mt-3" style={{ whiteSpace: 'pre-line' }}>
              {itemsSkuList.join('\r\n\n\n\n')}
            </p>
          );
        } else {
          return items[0].sku;
        }
      },
    },
    {
      Header: 'WPS Order',
      Cell: ({ cell }) => (
        <Button
          color="primary"
          style={{ fontSize: 12, width: 100, fontWeight: 600 }}
          onClick={() => {
            handleWpsInitOrder(
              cell.row.values.orderId,
              cell.row.values.items,
            );
          }}
        >
          WPS Order
        </Button>
      ),
    },
    {
      Header: 'AD Order',
      Cell: ({ cell }) => (
        <Button
          color="warning"
          style={{ fontSize: 12, width: 100, fontWeight: 600 }}
          onClick={() => {
            handleAdInitOrder(
              cell.row.values.orderId,
              cell.row.values.items,
            );
          }}
        >
          {!adSearching[cell.row.values.orderId]
            ? 'AD Order'
            : 'Searching...'}
        </Button>
      ),
    },
  ]);

  const fetchAllProducts = async () => {
    try {
      let response = await getShipStationOrders('awaiting_shipment');
      setData(response.data.orders);
      return response.data;
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchAllProducts();
  }, []);

  const handleChange = (e) => {
    getShipStationOrders(e.target.value)
      .then((data) => {
        setData(data.data.orders);
        return;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const confirmCartOrder = async (poNumber) => {
    await getCartInfo(poNumber)
      .then((res) => {
        if (res.data.status === 'OK') {
          res.data.items.map(async (item) => {
            let productName = await getProductSku(item.item);
            return (item.name = productName.name);
          });
          setCart(res.data);
          setConfirmModal(true);
          toggle();
        } else {
          notifyErr(`Could not find cart #${poNumber} in WPS`);
        }
      })
      .catch((err) => {
        notifyErr(err.message);
        console.log(err);
      });
    return;
  };

  return (
    <Container className="content-container">
      <Row className="justify-content-center page-header">
        <Col xs="12" className="text-center page-title">
          <img
            className="cascade-logo mb-3"
            src="/images/cascade-logo.jpg"
            alt=""
          />
          <h1>Orders Awaiting Shipment</h1>
        </Col>
        <Col xs="12" className="text-center wps-product-subtitle">
          <p>
            These are all of the orders that are currently awaiting
            shipment.
          </p>
        </Col>
        <Col xs="12">
          <FormGroup row>
            <Label for="exampleSelect" sm={2} className="text-center">
              Filter Orders
            </Label>
            <Col sm={10} md={8}>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                onChange={handleChange}
              >
                <option value="awaiting_shipment">
                  Awaiting Shipment
                </option>
                <option value="awaiting_payment">
                  Awaiting Payment
                </option>
                <option value="pending_fulfillment">
                  Pending Fulfillment
                </option>
                <option value="shipped">Shipped</option>
                <option value="on_hold">On Hold</option>
                <option value="cancelled">Cancelled</option>
              </Input>
            </Col>
          </FormGroup>
        </Col>
      </Row>
      {/* Dropship Order Table */}
      <OrdersTable columns={columns} data={data} />
      {/* AD Confirmation Modal */}
      <AdOrderConfirmationModal
        toggle={toggleAdConfirm}
        modal={adConfirmModal}
        order={calculatedOrder}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        confirmCartOrder={confirmCartOrder}
        setLoading={setLoading}
        loading={loading}
        shipping={shipping}
        setShipping={setShipping}
        createAutoDistOrder={createAutoDistOrder}
      />
      {/* WPS Create Cart Modal */}
      <OrderInfoModal
        toggle={toggle}
        modal={modal}
        order={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        confirmCartOrder={confirmCartOrder}
        setLoading={setLoading}
        loading={loading}
      />
      {/* WPS Confirm Order Modal */}
      <ConfirmModal
        toggle={toggleConfirm}
        modal={confirmModal}
        setSelectedOrder={setSelectedOrder}
        selectedOrder={selectedOrder}
        cart={cart}
        setCart={setCart}
        data={data}
        setData={setData}
      />
    </Container>
  );
};

export default FulfillOrders;
