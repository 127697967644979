import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import UpdateQuantityForm from './UpdateQuantityForm';
import axios from 'axios';
import Spinner from 'react-spinkit';
import { notifyErr, notifySuccess } from 'utils/toastMessages';

const SingleSync = () => {
  const [wpsProduct, setWpsProduct] = useState({});
  const [adProduct, setAdProduct] = useState({});
  const [edProduct, setEdProduct] = useState({});
  const [edAdProduct, setEdAdProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [adLoading, setAdLoading] = useState(false);
  const [canSyncWps, setCanSyncWps] = useState(false);
  const [canSyncAd, setCanSyncAd] = useState(false);

  const myEdQuantity = async (sku, wh) => {
    try {
      const response = await axios.get(
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/products/ed/${sku}?warehouse=${wh}&apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/ed/${sku}?warehouse=${wh}&apiKey=${process.env.REACT_APP_API_KEY}`,
      );
      if (response.data.error) {
        wh === 'WPS' ? setCanSyncWps(false) : setCanSyncAd(false);
        return notifyErr(response.data.message);
      } else {
        wh === 'WPS' ? setCanSyncWps(true) : setCanSyncAd(true);
        return response.data;
      }
    } catch (err) {
      console.log(err.message);
      notifyErr(err.message);
    }
  };

  const handleSupplierQty = async (values, wh) => {
    try {
      wh === 'WPS' ? setLoading(true) : setAdLoading(true);
      const response = await axios.get(
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/products/${
              wh === 'WPS' ? 'wps' : 'ad'
            }/${wh === 'WPS' ? values.wpsSku : values.adSku}?apiKey=${
              process.env.REACT_APP_API_KEY
            }`
          : `${
              process.env.REACT_APP_PRODUCTION_API_ENDPOINT
            }/products/${wh === 'WPS' ? 'wps' : 'ad'}/${
              wh === 'WPS' ? values.wpsSku : values.adSku
            }?apiKey=${process.env.REACT_APP_API_KEY}`,
      );
      if (wh === 'WPS') {
        const myQty = await myEdQuantity(values.wpsSku, wh);
        setWpsProduct({
          ...response.data,
        });
        setEdProduct({ ...myQty });
      } else if (wh === 'AD') {
        const myQty = await myEdQuantity(values.adSku, wh);
        setAdProduct({
          ...response.data,
        });
        setEdAdProduct({ ...myQty });
      }
      setTimeout(() => {
        setLoading(false);
        setAdLoading(false);
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const updateQuantityOnHand = async (sku, qty, wh) => {
    try {
      const response = await axios.post(
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_API_ENDPOINT}/products/update/${sku}/${qty}?warehouse=${wh}&apiKey=${process.env.REACT_APP_API_KEY}`
          : `${process.env.REACT_APP_PRODUCTION_API_ENDPOINT}/products/update/${sku}/${qty}?warehouse=${wh}&apiKey=${process.env.REACT_APP_API_KEY}`,
      );
      if (response.data.status === 'Success') {
        wh === 'WPS'
          ? setEdProduct({
              ...edProduct,
              quantityOnHand: qty,
            })
          : setEdAdProduct({
              ...edAdProduct,
              quantityOnHand: qty,
            });
        notifySuccess(
          `Product ${sku} quantity has successfully been synced to EcomDash`,
        );
      }
      if (response.data.status === 'Failure') {
        notifyErr(
          `Nothing changed, the quantity is already accurate in EcomDash`,
        );
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Container className="content-container">
      <Row className="justify-content-center page-header">
        <Col xs="12" className="text-center">
          <img
            className="cascade-logo mb-3"
            src="/images/cascade-logo.jpg"
            alt=""
          />
          <h1>WPS Search & Sync Single Item</h1>
        </Col>
        <Col xs="12">
          <p className="text-center">
            Enter the product sku or supplier product ID to search
            supplier database for the product.
            <br />
            You can sync this product quantity to your ecomdash
            account to reflect the amount that supplier has available
            by clicking on the "Sync Quantity button"
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="12" md="4">
          <img className="logo-120" src="/images/wps.png" alt="" />
          <UpdateQuantityForm
            handleRequest={handleSupplierQty}
            supplier="WPS"
          />
        </Col>
        <Col xs="12" md="6">
          {!loading ? (
            !wpsProduct.message ? (
              <div>
                <h3>{wpsProduct.name}</h3>
                <p>Product SKU: {wpsProduct.sku}</p>
                <p>
                  Product Supplier ID:{' '}
                  {wpsProduct.supplier_product_id}
                </p>
                <p>Available Quantity: {wpsProduct.quantities}</p>
                <hr />
                <p className="important-text">
                  My WPS Warehouse Quantity:{' '}
                  {edProduct.quantityOnHand}
                </p>
                <Button
                  color="primary"
                  disabled={!canSyncWps}
                  onClick={() => {
                    updateQuantityOnHand(
                      edProduct.sku,
                      wpsProduct.quantities,
                      'WPS',
                    );
                  }}
                >
                  Sync Quantity
                </Button>
              </div>
            ) : (
              <h5 className="text-center">{wpsProduct.message}</h5>
            )
          ) : (
            <Spinner name="folding-cube" className="home-spinner" />
          )}
        </Col>
      </Row>
      {/* Auto Dist Single Sync */}
      <Row className="justify-content-center page-header mt-4">
        <Col xs="12" className="text-center">
          <h1>Auto Dist Search & Sync Single Item</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="12" md="4">
          <img
            className="logo-120"
            src="/images/auto-dist.png"
            alt=""
          />
          <UpdateQuantityForm
            handleRequest={handleSupplierQty}
            supplier="AD"
          />
        </Col>
        <Col xs="12" md="6">
          {!adLoading ? (
            !adProduct.message ? (
              <div>
                <h3>{adProduct.name}</h3>
                <p>Product SKU: {adProduct.sku}</p>
                <p>Manufacturer Number: {adProduct.mfNo}</p>
                <p>Available Quantity: {adProduct.qty}</p>
                <hr />
                <p className="important-text">
                  My AD Warehouse Quantity:{' '}
                  {edAdProduct.quantityOnHand}
                </p>
                <Button
                  color="primary"
                  disabled={!canSyncAd}
                  onClick={() => {
                    updateQuantityOnHand(
                      edAdProduct.sku,
                      adProduct.qty,
                      'AD',
                    );
                  }}
                >
                  Sync Quantity
                </Button>
              </div>
            ) : (
              <h5 className="text-center">{adProduct.message}</h5>
            )
          ) : (
            <Spinner name="folding-cube" className="home-spinner" />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SingleSync;
