import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { createBrowserHistory } from 'history';
import React from 'react';
import { verifyToken } from 'utils/verifyToken';
import { useAppStateDispatch } from 'AppState';

let history = createBrowserHistory();

const App = () => {
  const dispatch = useAppStateDispatch();

  verifyToken()
    .then((data) => {
      if (data !== true) {
        dispatch({ type: 'logout' });
        localStorage.removeItem('x-token');
      }
    })
    .catch((err) => {
      console.log(err.message);
    });

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
};

export default App;
